<template>
  <el-card class="box-card">
    <div
      slot="header"
      class="clearfix">
      <span>Особенности</span>
    </div>
    <el-form-item label="Название">
      <el-input v-model="data.tireModelInfo.featuresTitle" />
    </el-form-item>
    <el-form-item label="Описание">
      <div
        v-for="(paragraph, i) in data.tireModelInfo.featuresDescription"
        :key="`paragraph-${i}`"
        class="description-paragraphs-list">
        <div class="description-paragraphs-list-item">
          <div class="description-paragraphs-item-inner">
            <span class="input-label">Параграф</span>
            <el-input
              v-model="data.tireModelInfo.featuresDescription[i]"
              type="textarea"
              :autosize="{ minRows: 1, maxRows: 20}" />
          </div>
          <span class="icon-delete">
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="() => deleteParagraph(i)" />
          </span>
        </div>
      </div>

      <el-button
        :style="{ width: '100%' }"
        icon="el-icon-plus"
        type="primary"
        plain
        @click="addNewParagraph">
        Добавить параграф
      </el-button>
    </el-form-item>

    <el-form-item label="Пункты">
      <div
        v-for="(point, i) in data.tireModelInfo.featuresPoints"
        :key="`paragraph-${i}`"
        class="description-paragraphs-list">
        <point-card
          :point="point"
          :add-new-point="addNewPoint"
          :delete-point="deletePoint" />
      </div>

      <el-button
        :style="{ width: '100%' }"
        icon="el-icon-plus"
        type="primary"
        plain
        @click="addNewPoint">
        Добавить пункт
      </el-button>
    </el-form-item>
  </el-card>
</template>
<script>
import PointCard from './PointCard';

export default {
  name: 'Features',
  components: {
    PointCard,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      tooltipText: 'Текст тултипа',
    };
  },
  methods: {
    addNewParagraph() {
      if (!this.data.tireModelInfo.featuresDescription) {
        this.data.tireModelInfo.featuresDescription = [];
      }
      this.data.tireModelInfo.featuresDescription.push('');
    },
    deleteParagraph(i) {
      this.data.tireModelInfo.featuresDescription.splice(i, 1);
    },
    addNewPoint() {
      if (!this.data.tireModelInfo.featuresPoints) {
        this.data.tireModelInfo.featuresPoints = [];
      }
      const newPoints = {
        title: '',
        text: '',
        footnotes: '',
        tooltipText: '',
      };
      this.data.tireModelInfo.featuresPoints.push(newPoints);
    },
    deletePoint(i) {
      this.data.tireModelInfo.featuresPoints.splice(i, 1);
    },
  },
};
</script>
<style lang="scss">
  .el-form-item__label {
    margin-top: 12px;
    line-height: 16px;
  }
  .description-paragraphs-item-inner {
    flex: 1;
  }
</style>
