<template>
  <el-card class="box-card">
    <div
      slot="header"
      class="clearfix">
      <span>Cпецификации</span>
    </div>
    <el-form-item label="Описание">
      <div
        v-for="(paragraph, i) in data.tireModelInfo.paragraphs"
        :key="`paragraph-${i}`"
        class="description-paragraphs-list">
        <div class="description-paragraphs-list-item card">
          <el-collapse>
            <span class="input-label">Параграф</span>
            <el-input
              v-model="paragraph.text"
              type="textarea"
              :autosize="{ minRows: 1, maxRows: 20}" />
            <el-collapse-item :title="tooltipText">
              <el-input
                v-model="paragraph.tooltipText"
                type="textarea"
                :autosize="{ minRows: 1, maxRows: 20}" />
            </el-collapse-item>
          </el-collapse>
          <span class="icon-delete">
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="() => deleteParagraph(i)" />
          </span>
        </div>
      </div>

      <el-button
        :style="{ width: '100%' }"
        icon="el-icon-plus"
        type="primary"
        plain
        @click="addNewParagraph">
        Добавить параграф
      </el-button>
    </el-form-item>

    <el-form-item label="Характеристики">
      <div>
        <span class="input-label">Назначение</span>
          <el-select
            remote
            v-model="data.purpose"
            value-key="id">
            <el-option
              v-for="[optionKey, optionValue] in entries(data.options.purpose)"
              :key="optionKey"
              :label="optionValue"
              :value="optionKey">
            </el-option>
          </el-select>
      </div>
      <div>
        <span class="input-label">Тип летних шин</span>
          <el-select
            remote
            v-model="data.summerTireType"
            value-key="id">
            <el-option
              v-for="[optionKey, optionValue] in entries(data.options.summerType)"
              :key="optionKey"
              :label="optionValue"
              :value="optionKey">
            </el-option>
          </el-select>
      </div>
      <div>
        <span class="input-label">Тип зимних шин</span>
          <el-select
            v-model="data.winterTireType"
            value-key="id">
            <el-option
              v-for="[optionKey, optionValue] in entries(data.options.winterType)"
              :key="optionKey"
              :label="optionValue"
              :value="optionKey">
            </el-option>
          </el-select>
      </div>
      <div>
        <span class="input-label">Рисунок протектора</span>
          <el-select
            v-model="data.treadPattern"
            value-key="id">
            <el-option
              v-for="[optionKey, optionValue] in entries(data.options.treadPattern)"
              :key="optionKey"
              :label="optionValue"
              :value="optionKey">
            </el-option>
          </el-select>
      </div>
      <div>
        <span class="input-label">Технология</span>
          <el-select
            v-model="data.technology"
            value-key="id">
            <el-option
              v-for="[optionKey, optionValue] in entries(data.options.technology)"
              :key="optionKey"
              :label="optionValue"
              :value="optionKey">
            </el-option>
          </el-select>
      </div>
      <div>
        <span class="input-label">Технология RunFlat</span>
          <el-select
            v-model="data.runFlat">
            <el-option
              v-for="[optionValue, optionKey] in entries(runFlatOptions)"
              :key="optionKey"
              :label="optionValue"
              :value="optionKey">
            </el-option>
          </el-select>
      </div>
    </el-form-item>

    <el-form-item label="Фото слайдера">
      <el-upload
        class="upload-demo"
        action=""
        :on-preview="() => true"
        :on-remove="() => true"
        :file-list="fileList"
        list-type="picture">
        <el-button
          size="small"
          type="primary">
          Нажмите для загрузки
        </el-button>
      </el-upload>
    </el-form-item>
  </el-card>
</template>
<script>
import { entries } from '@/utils';

export default {
  name: 'Specifications',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      runFlatOptions: { да: true, нет: false },
      tooltipText: 'Текст тултипа',
      fileList: [
        {
          name: 'food.jpeg',
          url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100',
        },
        {
          name: 'food2.jpeg',
          url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100',
        },
      ],
    };
  },
  methods: {
    entries,

    addNewParagraph() {
      if (!this.data.tireModelInfo.paragraphs) {
        this.data.tireModelInfo.paragraphs = [];
      }
      const newParagraph = {
        text: '',
        tooltipText: '',
      };
      this.data.tireModelInfo.paragraphs.push(newParagraph);
    },

    deleteParagraph(i) {
      this.data.tireModelInfo.paragraphs.splice(i, 1);
    },
  },
};
</script>
<style lang="scss">
  .input-label {
    display: block;
    font-size: 13px;
    line-height: 100%;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .description-paragraphs-list-item {
    border: 1px solid #DCDFE6;
    padding: 10px;
    margin-bottom: 20px;
    display: flex;
  }
  .icon-delete {
    margin-left: 10px;
    display: flex;
    align-items: flex-start;
  }
  .el-collapse {
    flex: 1;
    border: none;
    *:not(input,textarea) {
      border: none;
    }
  }
  .el-select {
    margin-bottom: 20px;
    width: 100%;
  }
</style>
