<template>
  <el-card class="box-card">
    <div
      slot="header"
      class="clearfix">
      <span>Главный банер</span>
    </div>
    <el-form-item label="Название">
      <el-input v-model="data.name" />
    </el-form-item>

    <el-form-item label="Новинка">
      <el-switch v-model="data.isNew" />
    </el-form-item>

    <el-form-item label="Сезон">
      <el-select
        remote
        v-model="data.season"
        value-key="id"
        >
        <el-option
          v-for="[optionKey, optionValue] in entries(data.options.season)"
          :key="optionKey"
          :label="optionValue"
          :value="{
            id: Number(optionKey),
            title: optionValue,
          }">
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="Сортировка">
      <el-input
        v-model="data.sort"
        type="number" />
    </el-form-item>

    <el-form-item label="Активный">
      <el-switch v-model="data.isActive" />
    </el-form-item>

    <el-form-item label="Заголовок">
      <el-input v-model="data.tireModelInfo.bannerTitle" />
      <el-collapse>
        <el-collapse-item title="Тултипы">
          <span class="input-label">Тултип десктоп</span>
          <el-input
            v-model="data.tireModelInfo.bannerTitleHint"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 20}" />
          <span class="input-label">Тултип мобилка</span>
          <el-input
            v-model="data.tireModelInfo.bannerTitleHintMobile"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 20}" />
        </el-collapse-item>
      </el-collapse>
    </el-form-item>

    <el-form-item label="Подзаголовок">
      <el-input v-model="data.tireModelInfo.bannerSubTitle" />
      <el-collapse>
        <el-collapse-item title="Тултипы">
          <span class="input-label">Тултип десктоп</span>
          <el-input
            v-model="data.tireModelInfo.bannerSubTitleHint"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 20}" />
          <span class="input-label">Тултип мобилка</span>
          <el-input
            v-model="data.tireModelInfo.bannerSubTitleHintMobile"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 20}" />
        </el-collapse-item>
      </el-collapse>
    </el-form-item>

    <el-form-item label="Иконки">
      <div
        v-for="(icon, i) in data.tireModelInfo.bannerIcons"
        :key="`icon-${i}`">
        <icons-card :ref="`icon-${i}`" :icons-data="data.tireModelInfo.bannerIcons" :id="i"/>
      </div>
      <el-button
        :style="{ width: '100%' }"
        icon="el-icon-plus"
        type="primary"
        plain
        @click="addNewIcon">
        Добавить параграф
      </el-button>
    </el-form-item>

    <el-form-item label="Марка №1">
      <el-switch v-model="data.tireModelInfo.isNumberOne" />
    </el-form-item>

    <el-form-item label="Качество">
      <el-switch v-model="data.tireModelInfo.isQuality" />
    </el-form-item>

    <el-form-item label="Показ: Более подробная информация...">
      <el-switch v-model="data.tireModelInfo.isBannerShowMoreInfoOnHover" />
    </el-form-item>

    <el-form-item label="Показ: Уже приобрели шину?">
      <el-switch v-model="data.tireModelInfo.isBannerShowdoubleGuarantee" />
    </el-form-item>

    <el-form-item label="Фон десктоп">
      <el-upload
        class="background-uploader"
        action="https://jsonplaceholder.typicode.com/posts/"
        :show-file-list="false"
        :on-success="() => {}"
        :before-upload="() => {}">
        <img
          v-if="background.pc"
          :src="background.pc"
          class="background">
        <i
          v-else
          class="el-icon-plus background-uploader-icon" />
      </el-upload>
    </el-form-item>

    <el-form-item label="Фон планшет">
      <el-upload
        class="background-uploader"
        action="https://jsonplaceholder.typicode.com/posts/"
        :show-file-list="false"
        :on-success="() => {}"
        :before-upload="() => {}">
        <img
          v-if="background.laptop"
          :src="background.laptop"
          class="background">
        <i
          v-else
          class="el-icon-plus background-uploader-icon" />
      </el-upload>
    </el-form-item>

    <el-form-item label="Фон телефон">
      <el-upload
        class="background-uploader"
        action="https://jsonplaceholder.typicode.com/posts/"
        :show-file-list="false"
        :on-success="() => {}"
        :before-upload="() => {}">
        <img
          v-if="background.mobile"
          :src="background.mobile"
          class="background">
        <i
          v-else
          class="el-icon-plus background-uploader-icon" />
      </el-upload>
    </el-form-item>

    <el-form-item label="Цвет фона">
      <el-input v-model="data.tireModelInfo.bannerBackgroundColor" />
    </el-form-item>
  </el-card>
</template>
<script>
import { entries, values } from '@/utils';
import IconsCard from './IconsCard.vue';

export default {
  name: 'MainBanner',
  components: {
    IconsCard,
  },
  inject: ['bindCallbackFromEditor'],
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      tooltipText: 'Текст тултипа',
      background: {
        pc: 'https://front.test.bridgestone.we-demonstrate2.ru/images/POTENZA-SPORT/main-background-desktop.jpg',
        laptop: 'https://front.test.bridgestone.we-demonstrate2.ru/images/POTENZA-SPORT/main-background-laptop.jpg',
        mobile: 'https://front.test.bridgestone.we-demonstrate2.ru/images/POTENZA-SPORT/main-background-mobile.jpg',
      },
    };
  },
  beforeMount() {
    this.bindCallbackFromEditor(this.getIconsData);
  },
  methods: {
    entries,

    addNewIcon() {
      const newIcon = { icon: [] };
      this.data.tireModelInfo.bannerIcons.push(newIcon);
    },

    deleteIcon(i) {
      this.data.tireModelInfo.bannerIcons.splice(i, 1);
    },

    getIconsData() {
      // TODO: придумать как переписать это по человечески
      const promises = values(this.$refs).map(([item], i) => item.$refs[`editor-${i}`].getValue());
      // ждем пока вызов getValue засейвит данныей у каждого эдитора
      return Promise.all(promises)
        .then()
        .catch(() => console.error('One more editor is broken'));
    },
  },
};
</script>
<style lang="scss">
  .background-uploader {
    .el-upload {
      line-height: 0;
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      i {
        line-height: 150px;
      }
    }
    .el-upload:hover {
      border-color: #409EFF;
    }
    .background {
      width: 200px;
      height: 150px;
      object-fit: contain;
    }
  }
  .background-uploader-icon {
    font-size: 30px;
    color: #8c939d;
    width: 200px;
    height: 150px;
    line-height: auto;
    text-align: center;
  }
</style>
