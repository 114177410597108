<template>
  <div class="icon-card card">
    <div class="icon-card-content">
      <span class="icon-text">
        <icon-description-editor
          v-if="isDataComplite"
          :id="id"
          :ref="uniqueId"
          :data="{ blocks: initialEditorData }"
          @onSave="getData" />
      </span>
    </div>
    <span class="icon-delete">
      <el-button
        type="danger"
        size="mini"
        icon="el-icon-delete"
        @click="() => deleteIcon(i)" />
    </span>
  </div>
</template>
<script>
import IconDescriptionEditor from '@/components/editors/iconDescriptionEditor';

export default {
  name: 'IconsCard',
  components: {
    IconDescriptionEditor,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
    iconsData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isDataComplite: false,
      initialEditorData: [],
    };
  },
  computed: {
    uniqueId() {
      return `editor-${this.id}`;
    },
  },
  async mounted() {
    this.initialEditorData = await this.prepareDataForEditor(this.iconsData[this.id].icon);
    this.isDataComplite = true;
  },
  methods: {
    objToHtmlString(obj) {
      const actions = {
        text: (item) => item.text,
        textTooltip: (item) => `<span 
          class="tooltip-label"
          data-tooltip-desktop="${item.tooltipDesktop}" 
          data-tooltip-mobile="${item.tooltipMobile}"
        >${item.text}</span>`,
      };

      return obj.reduce((acc, item) => acc + actions[item.itemtype](item), '');
    },

    prepareDataForEditor(rawData) {
      const actions = {
        iconImage: (data) => data,
        paragraph: ({ type, data }) => ({
          type,
          data: { text: this.objToHtmlString(data) },
        }),
      };

      return rawData.map((item) => actions[item.type](item));
    },

    prepareDataForBackend(rawData) {
      const actions = {
        iconImage: ({ type, data: { tooltipDesktop, tooltipMobile, url } }) => ({
          type,
          data: {
            tooltipDesktop,
            tooltipMobile,
            url,
          },
        }),
        paragraph: ({ type, tunes: { paragraphTune } }) => ({
          type,
          data: paragraphTune,
        }),
      };

      return rawData.blocks.map((item) => actions[item.type](item));
    },

    getData(data) {
      // записываем данные из эдитора в общий обътект для бека
      const preparedData = this.prepareDataForBackend(data);
      this.iconsData[this.id].icon = preparedData;
      return true;
    },
  },
};
</script>
<style lang="scss">
  .icon-card {
    display: flex;
    .icon-card-content {
      flex: 1;
    }
    .icon-uploader {
      margin-right: 10px;
      .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }
      .el-upload:hover {
        border-color: #409EFF;
      }
    }
    .icon-uploader-icon {
      font-size: 15px;
      color: #8c939d;
      width: 60px;
      height: 60px;
      line-height: 60px;
      text-align: center;
    }
    .icon-text {
      flex: 1;
    }
    .icon-delete {
      margin-left: 10px;
    }
  }
</style>
