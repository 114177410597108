<template>
  <div class="edit-landing">
    <el-form
      class="edit-landing-form"
      v-loading="isDataLoading"
      :disabled="isSaveDataLoading"
      ref="form"
      :model="tireModelData"
      label-width="120px">
        <main-banner :data="tireModelData" />
        <br>
        <specifications :data="tireModelData"/>
        <br>
        <homologation :data="tireModelData"/>
        <br>
        <features :data="tireModelData"/>
        <el-card class="box-card">
          <el-form-item>
            <el-button
              :loading="isSaveDataLoading"
              type="primary"
              @click="saveData">
              Сохранить
            </el-button>
            <el-button>Отмена</el-button>
          </el-form-item>
        </el-card>
    </el-form>
  </div>
</template>
<script>
import { mapMutations, mapActions, mapGetters } from 'vuex';
import clonedeep from 'lodash.clonedeep';
import MainBanner from '@/components/tireModelEdit/MainBanner';
import Specifications from '@/components/tireModelEdit/Specifications';
import Features from '@/components/tireModelEdit/Features';
import Homologation from '@/components/tireModelEdit/Homologation';

export default {
  name: 'TireModelEdit',
  provide() {
    return {
      bindCallbackFromEditor: this.bindCallbackFromEditor,
    };
  },
  components: {
    MainBanner,
    Specifications,
    Features,
    Homologation,
  },
  data() {
    return {
      tireModelData: {},
      saveDataFromEditor: null,
    };
  },
  computed: {
    ...mapGetters([
      'TIRE_MODEL_EDIT_DATA',
      'GET_TIRE_MODEL_EDIT_REQUEST_STATUS',
      'SAVE_TIRE_MODEL_EDIT_REQUEST_STATUS',
    ]),
    isDataLoading() {
      return this.GET_TIRE_MODEL_EDIT_REQUEST_STATUS === 'requested';
    },
    isSaveDataLoading() {
      return this.SAVE_TIRE_MODEL_EDIT_REQUEST_STATUS === 'requested';
    },
    tireModelId() {
      return this.$route.params.tire_model_id;
    },
  },
  watch: {
    TIRE_MODEL_EDIT_DATA() {
      this.tireModelData = clonedeep(this.TIRE_MODEL_EDIT_DATA);
    },
    SAVE_TIRE_MODEL_EDIT_REQUEST_STATUS(state) {
      if (state === 'success') {
        this.$notify({
          title: 'Готово',
          message: 'Данные успешно сохранены',
          type: 'success',
        });
      }
    },
  },
  beforeMount() {
    this.tireModelData = clonedeep(this.TIRE_MODEL_EDIT_DATA);
  },
  mounted() {
    this.setPageTitle('Редактор лендинга');
    this.getTireModelEditData(this.tireModelId);
  },
  methods: {
    ...mapActions(['getTireModelEditData', 'saveTireModelEditData']),
    ...mapMutations(['setPageTitle']),
    prepareDataForBackend(rawData) {
      const preparedData = clonedeep(rawData);
      const {
        tireModelInfo: {
          featuresDescription,
          paragraphs,
          featuresPoints,
        },
      } = preparedData;
      preparedData.tireModelInfo.featuresDescription = featuresDescription || [];
      preparedData.tireModelInfo.paragraphs = paragraphs || [];
      preparedData.tireModelInfo.featuresPoints = featuresPoints || [];
      preparedData.tireModelInfo.bannerImages = {
        mobile: {
          url: null,
          alt: null,
        },
        pc: {
          url: null,
          alt: null,
        },
        tablet: {
          url: null,
          alt: null,
        },
      };
      return preparedData;
    },
    async saveData() {
      await this.saveDataFromEditor();
      const tireModelData = this.prepareDataForBackend(this.tireModelData);
      this.saveTireModelEditData({
        id: this.tireModelId,
        tireModelData,
      });
    },
    bindCallbackFromEditor(callback) {
      this.saveDataFromEditor = callback;
    },
  },
};
</script>
<style lang="scss">
  .edit-landing {
    .box-card {
      text-align: left;
    }
    .card {
      padding: 10px;
      margin-top: 0;
      margin-bottom: 20px;
      border: 1px solid #DCDFE6;
      border-radius: 4px;
    }
  }
  .edit-landing-form {
    min-height: 80vh;
  }
</style>
