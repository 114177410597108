<template>
  <el-card class="box-card">
    <div
      slot="header"
      class="clearfix">
      <span>Омологации</span>
    </div>

    <el-form-item label="Активный">
      <el-switch v-model="data.tireModelInfo.isShowHomologations" />
    </el-form-item>

    <el-form-item label="Логотипы">
      <el-upload
        class="upload-demo"
        action=""
        :on-preview="() => true"
        :on-remove="() => true"
        :file-list="data.tireModelInfo.homologations || []"
        list-type="picture">
        <el-button
          size="small"
          type="primary">
          Нажмите для загрузки
        </el-button>
      </el-upload>
    </el-form-item>
  </el-card>
</template>
<script>

export default {
  name: 'Homologation',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
