<template>
  <div class="point-card card">
    <el-collapse>
      <span class="input-label">Заголовок</span>
      <el-input v-model="point.title" />
      <span class="input-label">Текст</span>
      <el-input
        v-model="point.text"
        type="textarea"
        :autosize="{ minRows: 1, maxRows: 20}" />
      <span class="input-label">Сноска</span>
      <el-input
        v-model="point.footnotes"
        type="textarea"
        :autosize="{ minRows: 1, maxRows: 20}" />
      <el-collapse-item :title="tooltipText">
        <el-input
          v-model="point.tooltipText"
          type="textarea"
          :autosize="{ minRows: 1, maxRows: 20}" />
      </el-collapse-item>
      <span class="input-label">Тип приложенных файлов</span>
      <el-cascader
        v-model="attachmentType"
        :options="attachmentTypes" />
      <div v-if="isSelectedType('image')">
        <el-upload
          class="upload-btn"
          action=""
          :on-preview="() => {}"
          :on-remove="() => {}"
          :multiple="false"
          list-type="picture">
          <el-button
            size="small"
            type="primary">
            Нажмите для загрузки
          </el-button>
          <div
            slot="tip"
            class="el-upload__tip">
            Разрешенные форматы изображений jpg/png/webp/gif
          </div>
        </el-upload>
      </div>
      <div v-if="isSelectedType('slider')">
        <el-upload
          class="upload-btn"
          action=""
          :on-preview="() => {}"
          :on-remove="() => {}"
          :file-list="fileList"
          list-type="picture">
          <el-button
            size="small"
            type="primary">
            Нажмите для загрузки
          </el-button>
          <div
            slot="tip"
            class="el-upload__tip">
            Разрешенные форматы изображений слайдера jpg/png/webp
          </div>
        </el-upload>
      </div>
      <div v-if="isSelectedType('video')">
        <span class="input-label">Десктоп версия видео</span>
        <el-upload
          class="upload-btn"
          action=""
          :on-change="() => {}"
          :multiple="false">
          <el-button
            size="small"
            type="primary">
            Нажмите для загрузки
          </el-button>
        </el-upload>
        <span class="input-label">Планшетная версия видео</span>
        <el-upload
          class="upload-btn"
          action=""
          :on-change="() => {}"
          :multiple="false">
          <el-button
            size="small"
            type="primary">
            Нажмите для загрузки
          </el-button>
        </el-upload>
        <span class="input-label">Мобильная версия видео</span>
        <el-upload
          class="upload-btn"
          action=""
          :on-change="() => {}"
          :multiple="false">
          <el-button
            size="small"
            type="primary">
            Нажмите для загрузки
          </el-button>
          <div
            slot="tip"
            class="el-upload__tip">
            Разрешенные форматы видео mp4/webm
          </div>
        </el-upload>
      </div>
    </el-collapse>
    <span class="icon-delete">
      <el-button
        size="mini"
        type="danger"
        icon="el-icon-delete"
        @click="() => deletePoint(i)" />
    </span>
  </div>
</template>
<script>
export default {
  name: 'PointCard',
  props: {
    point: {
      type: Object,
      default: () => ({
        title: '',
        text: '',
        footnotes: '',
        tooltipText: '',
      }),
    },
    fileList: {
      type: Array,
      default: () => [],
    },
    deletePoint: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      tooltipText: 'Текст тултипа',
      attachmentTypes: [
        {
          value: 'image',
          label: 'Изображение',
        },
        {
          value: 'slider',
          label: 'Слайдер',
        },
        {
          value: 'video',
          label: 'Видео',
        },
      ],
      attachmentType: ['image'],
    };
  },
  methods: {
    isSelectedType(type) {
      return this.attachmentType[0] === type;
    },
  },
};
</script>
<style lang="scss">
  .point-card {
    display: flex;
    align-items: flex-start;
    .el-cascader {
      margin: 20px 0;
    }
  }
</style>
