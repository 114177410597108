/* eslint-disable max-len */
import './tooltipInline.scss';

class TooltipInline {
  static get isInline() {
    return true;
  }

  constructor({ api, data, config = {} }) {
    const { location = 'bottom' } = config;
    this.tooltipLocation = location;

    this.spanTooltip = null;
    this.open = false;
    this.api = api;
    this.button = null;
    this.inputWrapper = null;
    this.tooltipDesktopInput = null;
    this.tooltipMobileInput = null;
    this.data = data;
    this.wrapper = undefined;
    this.toolboxIcon = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16" height="14" viewBox="0 0 21 21"><path d="M4,2H20A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H16L12,22L8,18H4A2,2 0 0,1 2,16V4A2,2 0 0,1 4,2M4,4V16H8.83L12,19.17L15.17,16H20V4H4M6,7H18V9H6V7M6,11H16V13H6V11Z" /></svg>';

    this.tag = 'SPAN';

    this.config = config;
  }

  clear() {
    this.open = false;
    this.tooltipDesktopInput.value = '';
    this.tooltipMobileInput.value = '';
  }

  surround(range) {
    if (this.spanTooltip) {
      this.unwrap(range);
      return;
    }

    this.wrap(range);
  }

  checkState() {
    this.spanTooltip = this.api.selection.findParentTag(this.tag, 'tooltip-label');

    if (this.spanTooltip) {
      this.fillInputs();
      this.showActions();
    } else {
      this.hideActions();
    }
  }

  renderActions() {
    this.inputWrapper = document.createElement('div');

    this.tooltipDesktopInput = document.createElement('INPUT');
    this.tooltipDesktopInput.classList.add('tooltip-input');
    this.tooltipDesktopInput.placeholder = 'Tooltip desktop';

    this.tooltipMobileInput = document.createElement('INPUT');
    this.tooltipMobileInput.classList.add('tooltip-input');
    this.tooltipMobileInput.placeholder = 'Tooltip mobile';

    if (this.spanTooltip) {
      this.tooltipMobileInput.value = this.spanTooltip.dataset.tooltipMobile;
      this.tooltipDesktopInput.value = this.spanTooltip.dataset.tooltipDesktop;
    }

    this.inputWrapper.appendChild(this.tooltipDesktopInput);
    this.inputWrapper.appendChild(this.tooltipMobileInput);

    this.inputWrapper.hidden = true;

    return this.inputWrapper;
  }

  fillInputs() {
    this.tooltipDesktopInput.value = this.spanTooltip.dataset.tooltipDesktop || '';
    this.tooltipMobileInput.value = this.spanTooltip.dataset.tooltipMobile || '';
  }

  showActions() {
    this.inputWrapper.hidden = false;
    this.api.listeners.on(this.inputWrapper, 'keydown', (e) => {
      if (e.key === 'Enter') {
        this.createTooltip();
      }
    }, false);
  }

  closeToolbar() {
    const toolbar = document.querySelector('.ce-inline-toolbar--showed');
    toolbar.classList.remove('ce-inline-toolbar--showed');
  }

  createTooltip() {
    const dTooltipText = this.tooltipDesktopInput.value;
    const mTooltipText = this.tooltipMobileInput.value;

    this.spanTooltip.dataset.tooltipDesktop = dTooltipText;
    this.spanTooltip.dataset.tooltipMobile = mTooltipText;

    let tooltipValue = '';

    if (dTooltipText) {
      tooltipValue += `Desktop: ${dTooltipText} `;
    }
    if (mTooltipText) {
      tooltipValue += `Mobile: ${mTooltipText}`;
    }
    const { tooltipLocation } = this;
    this.api.tooltip.onHover(this.spanTooltip, tooltipValue, { placement: tooltipLocation });
    this.closeToolbar();
  }

  hideActions() {
    this.inputWrapper.hidden = true;
  }

  wrap(range) {
    this.spanTooltip = document.createElement(this.tag);
    this.spanTooltip.classList.add('tooltip-label');

    const selectedText = range.extractContents();

    this.spanTooltip.appendChild(selectedText);
    range.insertNode(this.spanTooltip);
    this.api.selection.expandToTag(this.spanTooltip);
  }

  unwrap(range) {
    this.spanTooltip = this.api.selection.findParentTag(this.tag, 'tooltip-label');
    const text = range.extractContents();

    this.spanTooltip.remove();
    range.insertNode(text);
  }

  render() {
    this.button = document.createElement('button');
    this.button.type = 'button';
    this.button.classList.add('ce-inline-tool');
    this.button.innerHTML = this.toolboxIcon;

    return this.button;
  }

  static get sanitize() {
    return {
      span: () => ({
        class: true,
        'data-tooltip-desktop': true,
        'data-tooltip-mobile': true,
      }),
    };
  }
}
export default TooltipInline;
