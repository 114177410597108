<template>
  <div>
    <editor
      ref="editor"
      :holder="uniqeID"
      :config="{
        tools,
        data,
      }" />
  </div>
</template>
<script>
import TooltipInline from '@/editor-plugins/tooltip/tooltipInline.js';
import paragraphTune from '@/editor-plugins/tunes/paragraphTune.js';
import IconImage from '@/editor-plugins/iconImage/iconImage.js';

export default {
  name: 'IconDescriptionEditor',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tools: {
        iconImage: {
          class: IconImage,
        },
        paragraphTune,
        paragraph: {
          tunes: ['paragraphTune'],
        },
        Tooltip: {
          class: TooltipInline,
        },
      },
    };
  },
  computed: {
    uniqeID() {
      return `editor-${Math.floor(Math.random() * 10000)}`;
    },
  },
  methods: {
    getValue() {
      // eslint-disable-next-line no-underscore-dangle
      return this.$refs.editor._data.state.editor.save()
        .then((data) => this.$emit('onSave', data))
        .catch((err) => { console.error(err); });
    },
  },
};
</script>
<style lang="scss">
  .codex-editor {
    border: 1px solid #DCDFE6;
    border-radius: 4px;
  }
</style>
