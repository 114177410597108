var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-card',{staticClass:"box-card"},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("Cпецификации")])]),_c('el-form-item',{attrs:{"label":"Описание"}},[_vm._l((_vm.data.tireModelInfo.paragraphs),function(paragraph,i){return _c('div',{key:("paragraph-" + i),staticClass:"description-paragraphs-list"},[_c('div',{staticClass:"description-paragraphs-list-item card"},[_c('el-collapse',[_c('span',{staticClass:"input-label"},[_vm._v("Параграф")]),_c('el-input',{attrs:{"type":"textarea","autosize":{ minRows: 1, maxRows: 20}},model:{value:(paragraph.text),callback:function ($$v) {_vm.$set(paragraph, "text", $$v)},expression:"paragraph.text"}}),_c('el-collapse-item',{attrs:{"title":_vm.tooltipText}},[_c('el-input',{attrs:{"type":"textarea","autosize":{ minRows: 1, maxRows: 20}},model:{value:(paragraph.tooltipText),callback:function ($$v) {_vm.$set(paragraph, "tooltipText", $$v)},expression:"paragraph.tooltipText"}})],1)],1),_c('span',{staticClass:"icon-delete"},[_c('el-button',{attrs:{"size":"mini","type":"danger","icon":"el-icon-delete"},on:{"click":function () { return _vm.deleteParagraph(i); }}})],1)],1)])}),_c('el-button',{style:({ width: '100%' }),attrs:{"icon":"el-icon-plus","type":"primary","plain":""},on:{"click":_vm.addNewParagraph}},[_vm._v(" Добавить параграф ")])],2),_c('el-form-item',{attrs:{"label":"Характеристики"}},[_c('div',[_c('span',{staticClass:"input-label"},[_vm._v("Назначение")]),_c('el-select',{attrs:{"remote":"","value-key":"id"},model:{value:(_vm.data.purpose),callback:function ($$v) {_vm.$set(_vm.data, "purpose", $$v)},expression:"data.purpose"}},_vm._l((_vm.entries(_vm.data.options.purpose)),function(ref){
var optionKey = ref[0];
var optionValue = ref[1];
return _c('el-option',{key:optionKey,attrs:{"label":optionValue,"value":optionKey}})}),1)],1),_c('div',[_c('span',{staticClass:"input-label"},[_vm._v("Тип летних шин")]),_c('el-select',{attrs:{"remote":"","value-key":"id"},model:{value:(_vm.data.summerTireType),callback:function ($$v) {_vm.$set(_vm.data, "summerTireType", $$v)},expression:"data.summerTireType"}},_vm._l((_vm.entries(_vm.data.options.summerType)),function(ref){
var optionKey = ref[0];
var optionValue = ref[1];
return _c('el-option',{key:optionKey,attrs:{"label":optionValue,"value":optionKey}})}),1)],1),_c('div',[_c('span',{staticClass:"input-label"},[_vm._v("Тип зимних шин")]),_c('el-select',{attrs:{"value-key":"id"},model:{value:(_vm.data.winterTireType),callback:function ($$v) {_vm.$set(_vm.data, "winterTireType", $$v)},expression:"data.winterTireType"}},_vm._l((_vm.entries(_vm.data.options.winterType)),function(ref){
var optionKey = ref[0];
var optionValue = ref[1];
return _c('el-option',{key:optionKey,attrs:{"label":optionValue,"value":optionKey}})}),1)],1),_c('div',[_c('span',{staticClass:"input-label"},[_vm._v("Рисунок протектора")]),_c('el-select',{attrs:{"value-key":"id"},model:{value:(_vm.data.treadPattern),callback:function ($$v) {_vm.$set(_vm.data, "treadPattern", $$v)},expression:"data.treadPattern"}},_vm._l((_vm.entries(_vm.data.options.treadPattern)),function(ref){
var optionKey = ref[0];
var optionValue = ref[1];
return _c('el-option',{key:optionKey,attrs:{"label":optionValue,"value":optionKey}})}),1)],1),_c('div',[_c('span',{staticClass:"input-label"},[_vm._v("Технология")]),_c('el-select',{attrs:{"value-key":"id"},model:{value:(_vm.data.technology),callback:function ($$v) {_vm.$set(_vm.data, "technology", $$v)},expression:"data.technology"}},_vm._l((_vm.entries(_vm.data.options.technology)),function(ref){
var optionKey = ref[0];
var optionValue = ref[1];
return _c('el-option',{key:optionKey,attrs:{"label":optionValue,"value":optionKey}})}),1)],1),_c('div',[_c('span',{staticClass:"input-label"},[_vm._v("Технология RunFlat")]),_c('el-select',{model:{value:(_vm.data.runFlat),callback:function ($$v) {_vm.$set(_vm.data, "runFlat", $$v)},expression:"data.runFlat"}},_vm._l((_vm.entries(_vm.runFlatOptions)),function(ref){
var optionValue = ref[0];
var optionKey = ref[1];
return _c('el-option',{key:optionKey,attrs:{"label":optionValue,"value":optionKey}})}),1)],1)]),_c('el-form-item',{attrs:{"label":"Фото слайдера"}},[_c('el-upload',{staticClass:"upload-demo",attrs:{"action":"","on-preview":function () { return true; },"on-remove":function () { return true; },"file-list":_vm.fileList,"list-type":"picture"}},[_c('el-button',{attrs:{"size":"small","type":"primary"}},[_vm._v(" Нажмите для загрузки ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }