export default class {
  static get isTune() {
    return true;
  }

  constructor({
    api, data, config, block,
  }) {
    this.api = api;
    this.data = data;
    this.config = config;
    this.block = block;
  }

  parseHtml(htmlElem) {
    const text = [];

    htmlElem.childNodes.forEach((elem) => {
      if (elem.tagName) {
        const newItem = {
          itemtype: 'textTooltip',
          text: elem.innerText,
          tooltipMobile: elem.dataset.tooltipMobile || '',
          tooltipDesktop: elem.dataset.tooltipDesktop || '',
        };
        text.push(newItem);
        return;
      }
      const newItem = {
        itemtype: 'text',
        text: elem.data,
      };

      text.push(newItem);
    });

    return text;
  }

  save() {
    const { id } = this.block;
    const block = this.api.blocks.getById(id).holder;
    const currentblock = block.querySelector('.ce-paragraph');
    return this.parseHtml(currentblock);
  }
}
